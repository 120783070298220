@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap");

:root {
  --bg-color: #090b0c;
  --hero-bg: #161b1e;
  --article-bg: #161b1e;
  --green: #00b48b;
  --white: #fff;
  --gray: #112827;
  --border-radius: 100px 0 100px 0;
}

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
  width: 100%;
  border-radius: 40px 0 40px 0;
}

img.logo {
  border-radius: 0;
}

ul,
li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  user-select: none;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: #ffffff;
  font-size: 16px;
}

p {
  line-height: 1.8;
  font-size: 1rem;
}

/* Underline */
.green-underline {
  background-color: var(--green);
  height: 2px;
  width: 80px;
  margin: 0.5rem 0;
}

.green__centered__underline {
  background-color: var(--green);
  height: 2px;
  width: calc(50vw - 100px);
  margin: 0 auto 1.25rem;
  min-width: calc(40vw - 100px);
}

.white-underline {
  background-color: var(--white);
  height: 2px;
  width: 80px;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.btn {
  font-weight: 500;
  padding: 0.5rem 2rem;
  border-width: 2px;
  border-style: solid;
  border-color: inherit;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.btn:hover {
  background-color: transparent;
  color: inherit;
}

.btn-green {
  background-color: var(--green);
  border-color: var(--green);
  color: var(--white);
}

.btn-green:hover {
  color: var(--green);
}

.btn-white {
  background-color: var(--white);
  border-color: var(--white);
  color: var(--gray);
}

.btn-white-text-green {
  background-color: var(--white);
  border-color: var(--white);
  color: var(--green);
}

/* Homepage CRM Slider section buttons */
.crm__buttons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.crm__buttons button {
  margin-bottom: 1rem;
  position: absolute;
}

.crm__buttons button:first-child {
  left: 0;
}

.crm__buttons button:nth-child(2) {
  right: 0;
  top: 3rem;
}

.crm__buttons button:nth-child(3) {
  left: 0;
  top: 6rem;
}

.crm__buttons button:nth-child(4) {
  right: 0;
  top: 9rem;
}

.header {
  position: absolute;
  width: 100%;
  padding: 1rem;
}

.navbar {
  position: absolute;
  right: 20px;
  top: -1000%;
  z-index: -1;
  width: calc(100vw - 50px);
  transition: top 0.4s ease-in-out;
}

.navbar.open {
  top: 80px;
  z-index: 10;
  background-color: #111827;
  border-radius: 5px;
  padding: 20px;
}

.home {
  background-color: var(--hero-bg);
  height: calc(100vh - 100px);
}

.home .overlay {
  height: calc(100vh - 100px);
}

.technologies {
  background: url("./assets/technologies-hero.jpg") no-repeat center/cover;
}

.gray-text {
  color: hsl(0, 0%, 100%, 60%);
}

.white-text {
  color: var(--white);
}

.article__cards {
  background-color: var(--article-bg);
  transition: all 0.3s ease-in-out;
  border-radius: 1rem;
}

.light__green {
  background-color: var(--green);
}

.light__green__text {
  color: var(--green);
}

.img {
  border-radius: 0 40px 0 40px;
}

.img__about__us {
  border-radius: none;
}

.web__design__div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.web__design__div:nth-child(odd) img {
  border-radius: 0 40px 0 40px;
}

input {
  background-color: transparent;
  color: var(--white);
  border-bottom: 2px solid var(--white);
  padding: 0.5rem 1rem;
}

input::placeholder {
  color: hsl(0, 0%, 100%, 60%);
}

span {
  font-weight: 300;
}

.privacy__policy h4 {
  font-size: 1.25rem;
  margin-top: 3rem;
}

.privacy__policy p {
  margin: 1rem 0;
  color: #112827d3;
}

@media (min-width: 768px) {
  .header {
    position: absolute;
    width: calc(100vw - 50px);
    left: 50%;
    transform: translateX(-50%);
  }

  .navbar.open {
    width: calc(100vw - 100px);
  }

  .home {
    border-radius: var(--border-radius);
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  .web__design__div {
    flex-direction: row;
  }

  .web__design__div:nth-child(even) {
    flex-direction: row-reverse;
  }

  .web__design__div article {
    width: 50%;
  }

  .crm__buttons {
    flex-direction: row;
    justify-content: center;
  }

  .crm__buttons button {
    margin-bottom: 1rem;
    margin-right: 1rem;
    position: relative;
  }

  .crm__buttons button:first-child {
    left: 0;
  }

  .crm__buttons button:nth-child(2) {
    right: 0;
    top: 0;
  }

  .crm__buttons button:nth-child(3) {
    left: 0;
    top: 0;
  }

  .crm__buttons button:nth-child(4) {
    right: 0;
    top: 0;
  }
}

@media (min-width: 1024px) {
  .homepage {
    height: calc(90vh - 100px);
    margin-top: 3rem;
  }

  .homepage__cards {
    position: absolute;
    width: 350px;
  }

  .homepage__cards:first-child {
    left: 0;
  }

  .homepage__cards:nth-child(2) {
    right: 0;
  }

  .homepage__cards:nth-child(3) {
    left: 50%;
    top: 42%;
    transform: translate(-50%, -42%);
  }

  .homepage__cards:nth-child(4) {
    right: 0;
    top: 21.5rem;
  }

  .homepage__cards:nth-child(5) {
    left: 0;
    top: 21.5rem;
  }
}

@media (min-height: 800px) {
  .homepage {
    height: 570px;
  }

  .homepage__cards:nth-child(3) {
    left: 50%;
    top: 40%;
    transform: translate(-50%, -40%);
  }
}

@media (min-width: 1280px) {
  .header {
    width: calc(100vw - 200px);
  }

  h1 {
    font-size: 5rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 3rem;
  }

  h4 {
    font-size: 2rem;
  }
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-links {
  display: flex;
}
.social-links img {
  border-radius: 0;
}